import { withYup } from '@remix-validated-form/with-yup';
import { type V2ActionHandler, actionV2Response, handleActionV2 } from '@storefront/util/handleAction.server';
import { FormValidationError } from '@storefront/util/validation/validation-error';
import type { ActionFunctionArgs } from '@vercel/remix';
import * as Yup from 'yup';
import { getUserSession, setUserSessionCookie } from '~/session/user-session';
import { createMedusaClient } from '../../libs/util/medusa/client.server';

// Regular expressions to validate US and Canadian ZIP/postal codes
const usZipCodeRegex = /^\d{5}(-\d{4})?$/;
const caPostalCodeRegex = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;

export const postalCodeFieldYupValidation = Yup.string()
  .required('Postal Code is required.')
  .test('is-valid-postal-code', 'Please enter a valid US or Canadian postal code', (val) => {
    if (!val) return false;
    const sanitizedVal = val.replace(/\s/g, ''); // Remove whitespace
    // Check if it matches either US or Canadian format
    return usZipCodeRegex.test(sanitizedVal) || caPostalCodeRegex.test(sanitizedVal);
  });

export const postalCodeValidator = withYup(
  Yup.object().shape({
    postalCode: postalCodeFieldYupValidation,
    productId: Yup.string().optional(),
  }),
);

export enum PostalCodeActions {
  verifyPostalCode = 'verifyPostalCode',
}

export type VerifyPostalCodePayload = {
  postalCode: string;
  productId?: string;
};

export interface VerifyPostalCodeResponse {
  valid: boolean;
  postalCode: string;
}

const verifyPostalCode: V2ActionHandler<VerifyPostalCodeResponse | Response> = async (
  payload: VerifyPostalCodePayload,
  { request, response },
) => {
  const session = await getUserSession(request.headers.get('Cookie'));

  const client = await createMedusaClient({ request });

  const result = await postalCodeValidator.validate(payload);

  if (result.error) throw new FormValidationError(result.error);

  const { postalCode } = payload;

  const validationResponse = await client.postalCode.validatePostalCode({ postalCode });
  const isSupported = validationResponse.isValid;
  const warehouseId = validationResponse.warehouseId;
  const partnerId = validationResponse.partnerId;

  session.set('user-postal-code', {
    city: 'Toronto',
    postalCode,
    isSupportedPostalCode: isSupported,
    warehouseId,
    partnerId,
  });

  if (response) {
    await setUserSessionCookie(response?.headers, session);
  }

  return actionV2Response<VerifyPostalCodeResponse | Response>({ valid: isSupported, postalCode }, request);
};

const actions = {
  verifyPostalCode,
};

export async function action(actionArgs: ActionFunctionArgs) {
  return await handleActionV2({ actionArgs, actions });
}
